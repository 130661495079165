
.app-cta3-wrapper {
    $primaryBtnColor: #fef85f;
    $secondaryBtnColor: #000;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 45vh;
    height:auto;  
    
    .backgroundimage-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.3);
    }
  
    .app-cta3 {
      position: relative;
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;

      &.app-cta3-text-image {
        padding-top: 0;
        padding-bottom: 0;
      }
  
      @include media-breakpoint-up(md) {
        padding-top: $grid-gutter-width*2;
        padding-bottom: $grid-gutter-width*2;
      }
      
      @include media-breakpoint-up(lg) {
          padding-top: $grid-gutter-width*3;
          padding-bottom: $grid-gutter-width*3;
      }
  
      h2 {
        margin-bottom: 16px;
      }
  
      // .app-cta3-text {
      //   p {
      //     font-size: 20px;
      //     line-height: 29px;
      //     margin-bottom: 0;
  
      //     @include media-breakpoint-up(md) {
      //       font-size: 30px;
      //       line-height: 42px;
      //     }
          
      //   }
      // }
  
      .app-cta3-text + .app-cta3-buttons {
        margin-top: 16px;
      }
  
      .app-cta3-buttons {
        .app-cta3-btn {
          font-size: 16px;
          // line-height: 40px;
          font-weight: 700;
          // min-height: 40px;
          padding: 8px 16px;
          border-radius: 4px;
          color: $white;
          transition: all .2s ease;
  
          &:first-child {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.25), 0 0 10px rgba(0, 0, 0, 0.22);
            color: #000;
          }
  
          @include media-breakpoint-up(md) {
            line-height: 50px;
            height: 50px;
            padding: 0 32px;
          }
          
          &:first-child {
            background-color: $primaryBtnColor;
            border-color: $primaryBtnColor;
  
            &:hover {
              color: $white;
              background-color: $secondary;
              border-color: $secondary;
            }
          }
  
          &:not(:first-child) {
            color: $secondaryBtnColor;
            border-color: $secondaryBtnColor;
  
            &:hover {
              color: $white;
              background-color: $secondaryBtnColor;
              border-color: $secondaryBtnColor;
            }
          }
        }
      }
  
      &.text-bright {
        color: $white;
  
        h1, h2, h3, h4, h5, h6 {
          color: $white;
        }
  
        .app-cta3-buttons {
          .app-cta3-btn {
            &:not(:first-child) {
              border-color: $white;
              color: $white;
  
              &:hover {
                border-color: $secondaryBtnColor;
              }
            }
          }
        }
      }
  
      &.text-dark {
        color: $black;
        
        .app-cta3-buttons {
          .app-cta3-btn {
            &:first-child {
              color: $white;
  
              &:hover {
                color: $primaryBtnColor;
              }
            }
  
            color: $black;
          }
        }
      }
    }
  }